var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-article"},[_c('Navbar'),_c('div',{staticClass:"jumbotron jumbotron-fluid bg-transparent mt-4"},[_c('div',{staticClass:"container text-center article-corona"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-7 col-lg-6"},[_c('h1',{directives:[{name:"lazy-container",rawName:"v-lazy-container",value:({ selector: 'img' }),expression:"{ selector: 'img' }"}],staticStyle:{"font-weight":"600"}},[_vm._v(" Kumpulan "),_c('span',[_vm._v("Tulisan")]),_vm._v(" "),_c('br'),_c('img',{staticClass:"d-none d-lg-inline",attrs:{"data-src":"/assets/icons/virus3.svg","alt":"virus"}}),_vm._v(" Masyarakat ")]),_vm._m(0),_c('form',{staticClass:"mt-5 d-flex justify-content-center",attrs:{"onSubmit":"return false;"}},[_c('div',{staticClass:"form-group d-flex input-search justify-content-center shadow-sm",class:{ active: _vm.searchActive }},[_c('button',{directives:[{name:"lazy-container",rawName:"v-lazy-container",value:({ selector: 'img' }),expression:"{ selector: 'img' }"}],staticClass:"btn",staticStyle:{"cursor":"default"},attrs:{"type":"button"}},[_c('img',{attrs:{"data-src":"/assets/icons/search.svg","alt":"search"}})]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control border-0 w-100 ml-2 bg-transparent",attrs:{"type":"text","id":"search","placeholder":"cari..."},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value},_vm.getSearchArticles]}})])]),(_vm.searchArticles.length > 0)?_c('div',{staticClass:"card card-search mx-auto border-0 shadow-sm",staticStyle:{"margin-top":"-20px"}},[_c('div',{staticClass:"card-body"},_vm._l((_vm.searchArticles),function(article){return _c('router-link',{key:article.id,staticClass:"text-decoration-none",attrs:{"to":{
                  name: 'ArticleDetail',
                  params: { slug: article.slug },
                }}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex align-items-center"},[_c('div',[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                          ("https://dashboard.infoin.auroraweb.id/storage/" + (article.thumbnail))
                        ),expression:"\n                          `https://dashboard.infoin.auroraweb.id/storage/${article.thumbnail}`\n                        "}],attrs:{"alt":article.title,"height":"50"}})]),_c('div',{staticClass:"text-left ml-3"},[_c('h5',{staticClass:"m-0",staticStyle:{"font-weight":"600","color":"black"}},[_vm._v(" "+_vm._s(_vm.strippedContent(article.title, 20))+".. ")]),_c('span',[_vm._v(" "+_vm._s(_vm.setDate(article.created_at, "LL"))+" • "+_vm._s(article.min_read)+" min read ")])])])]),(
                    article.id != _vm.searchArticles[_vm.searchArticles.length - 1].id
                  )?_c('hr'):_vm._e()])}),1)]):_vm._e()])])])]),_c('section',{staticClass:"recent-posts"},[_c('div',{staticClass:"container",attrs:{"data-aos":"fade-up","data-aos-duration":"1000"}},[_vm._m(1),(_vm.latestArticles.length > 0)?_c('VueSlickCarousel',_vm._b({staticClass:"row justify-content-center mt-4 vue-carousel",scopedSlots:_vm._u([{key:"prevArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow prevArrow d-none d-md-block"},[_vm._v(" "+_vm._s(arrowOption.currentSlide)+"/"+_vm._s(arrowOption.slideCount)+" ")])]}},{key:"nextArrow",fn:function(arrowOption){return [_c('div',{staticClass:"custom-arrow nextArrow d-none d-md-block"},[_vm._v(" "+_vm._s(arrowOption.currentSlide)+"/"+_vm._s(arrowOption.slideCount)+" ")])]}}],null,false,3685235529)},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.latestArticles),function(article){return _c('div',{key:article.id,staticClass:"col-md-12"},[_c('div',{staticClass:"card mb-3 shadow p-3"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-6 thumbnail-post"},[_c('router-link',{attrs:{"to":{
                    name: 'ArticleDetail',
                    params: { slug: article.slug },
                  }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                      ("https://dashboard.infoin.auroraweb.id/storage/" + (article.thumbnail))
                    ),expression:"\n                      `https://dashboard.infoin.auroraweb.id/storage/${article.thumbnail}`\n                    "}],staticClass:"card-img",attrs:{"alt":article.title}})])],1),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card-body d-flex flex-column justify-content-between"},[_c('div',[_c('router-link',{staticClass:"badge-pill badge-main text-white text-decoration-none",attrs:{"to":{
                        name: 'Category',
                        params: { slug: article.categori.slug },
                      }}},[_vm._v(_vm._s(article.categori.name))]),_c('router-link',{staticClass:"text-decoration-none card-title",attrs:{"to":{
                        name: 'ArticleDetail',
                        params: { slug: article.slug },
                      }}},[(article.title.length > 40)?_c('h2',{staticClass:"card-title mt-3"},[_vm._v(" "+_vm._s(_vm.strippedContent(article.title, 40))+".. ")]):_c('h2',{staticClass:"card-title mt-3"},[_vm._v(" "+_vm._s(_vm.strippedContent(article.title, 40))+" ")])]),_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.strippedContent(article.content, 100))+".. ")])],1),_c('div',{staticClass:"media d-flex align-items-center mt-3"},[_c('router-link',{staticClass:"media-header mr-3",attrs:{"to":{
                        name: 'Profile',
                        params: { slug: article.user.slug },
                      }}},[(article.user.google_id)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(article.user.avatar),expression:"article.user.avatar"}],staticClass:"rounded-circle",attrs:{"alt":"author","width":"50","height":"50"}}):_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                          ("https://dashboard.infoin.auroraweb.id/storage/" + (article.user.avatar))
                        ),expression:"\n                          `https://dashboard.infoin.auroraweb.id/storage/${article.user.avatar}`\n                        "}],staticClass:"rounded-circle",attrs:{"alt":"author","width":"50","height":"50"}})]),_c('div',{staticClass:"media-body"},[_c('router-link',{attrs:{"to":{
                          name: 'Profile',
                          params: { slug: article.user.slug },
                        }}},[_c('h5',{staticClass:"m-0"},[_vm._v(_vm._s(article.user.name))])]),_c('span',[_vm._v(" "+_vm._s(_vm.setDate(article.created_at, "LL"))+" • "+_vm._s(article.min_read)+" min read ")])],1)],1)])])])])])}),0):_c('div',{staticClass:"row justify-content-center mt-4 vue-carousel"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card mb-3 shadow p-3"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-md-6 thumbnail-post"},[_c('b-skeleton-img')],1),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card-body d-flex flex-column justify-content-between"},[_c('div',[_c('b-skeleton',{attrs:{"animation":"wave","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"55%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"70%"}})],1),_c('div',{staticClass:"media d-flex align-items-center mt-3"},[_c('b-skeleton',{attrs:{"type":"avatar"}}),_vm._v(" "),_c('br'),_c('div',{staticClass:"media-body ml-2"},[_c('b-skeleton',{attrs:{"animation":"wave","width":"30%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"50%"}})],1)],1)])])])])])])],1)]),_c('section',{staticClass:"old-post mb-5",staticStyle:{"margin-top":"100px"},attrs:{"id":"old-post"}},[_c('div',{staticClass:"container post-wrap pt-3"},[_vm._m(2),(_vm.articles.data.length > 0)?_c('div',{staticClass:"row mt-4"},_vm._l((_vm.articles.data),function(article){return _c('div',{key:article.id,staticClass:"col-md-6 col-lg-6 col-xl-4 my-3"},[_c('div',{staticClass:"card"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                name: 'ArticleDetail',
                params: { slug: article.slug },
              }}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                  ("https://dashboard.infoin.auroraweb.id/storage/" + (article.thumbnail))
                ),expression:"\n                  `https://dashboard.infoin.auroraweb.id/storage/${article.thumbnail}`\n                "}],staticClass:"card-img-top",attrs:{"alt":article.title,"height":"250px"}})]),_c('div',{staticClass:"card-body d-flex flex-column justify-content-between"},[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                  name: 'ArticleDetail',
                  params: { slug: article.slug },
                }}},[(article.title.length > 50)?_c('h5',{staticClass:"card-title",staticStyle:{"font-family":"'Poppins', sans-serif","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.strippedContent(article.title, 50))+".. ")]):_c('h5',{staticClass:"card-title",staticStyle:{"font-family":"'Poppins', sans-serif","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.strippedContent(article.title, 50))+" ")])]),_c('div',{staticClass:"author d-flex mt-4 align-items-center"},[_c('router-link',{staticClass:"author-img",attrs:{"to":{
                    name: 'Profile',
                    params: { slug: article.user.slug },
                  }}},[(article.user.google_id)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(article.user.avatar),expression:"article.user.avatar"}],staticClass:"rounded-circle",attrs:{"alt":"author","width":"50","height":"50"}}):_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(
                      ("https://dashboard.infoin.auroraweb.id/storage/" + (article.user.avatar))
                    ),expression:"\n                      `https://dashboard.infoin.auroraweb.id/storage/${article.user.avatar}`\n                    "}],staticClass:"rounded-circle",attrs:{"alt":"author","width":"50","height":"50"}})]),_c('div',{staticClass:"author-name ml-3"},[_c('p',{staticClass:"m-0"},[_c('router-link',{attrs:{"to":{
                        name: 'Profile',
                        params: { slug: article.user.slug },
                      }}},[_vm._v(_vm._s(article.user.name))]),_vm._v(" in "),_c('router-link',{attrs:{"to":{
                        name: 'Category',
                        params: { slug: article.categori.slug },
                      }}},[_vm._v(_vm._s(article.categori.name))])],1),_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.setDate(article.created_at, "LL"))+" • "+_vm._s(article.min_read)+" min read ")])])],1)],1)],1)])}),0):_c('div',{staticClass:"row mt-4"},_vm._l((3),function(n){return _c('div',{key:n,staticClass:"col-md-6 col-lg-6 col-xl-4 my-3"},[_c('div',{staticClass:"card"},[_c('b-skeleton-img',{attrs:{"no-aspect":"true","height":"250px"}}),_c('div',{staticClass:"card-body d-flex flex-column justify-content-between"},[_c('div',[_c('b-skeleton',{attrs:{"animation":"wave","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"55%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"70%"}})],1),_c('div',{staticClass:"author d-flex mt-4 align-items-center"},[_c('div',{staticClass:"author-img"},[_c('b-skeleton',{attrs:{"type":"avatar"}})],1),_c('div',{staticClass:"author-name ml-3 w-100"},[_c('b-skeleton',{attrs:{"animation":"wave","width":"30%"}}),_c('b-skeleton',{attrs:{"animation":"wave","width":"50%"}})],1)])])],1)])}),0),(_vm.articles.data.length > 0)?_c('div',{staticClass:"row justify-content-center"},[_c('pagination',{attrs:{"data":_vm.articles,"align":'center'},on:{"pagination-change-page":_vm.getArticles}})],1):_vm._e()])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"lead mt-4"},[_vm._v(" keluarkan info yang kalian ketahui lewat "),_c('br'),_vm._v(" tulisan, lalu bagi info-info itu ke masyarakat lain. ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-12 col-lg-8 hero-title"},[_c('span',{staticClass:"rounded-pill"}),_c('h3',[_vm._v("Latest Article")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-12 col-lg-8 hero-title"},[_c('span',{staticClass:"rounded-pill"}),_c('h3',[_vm._v("All Article")])])])}]

export { render, staticRenderFns }